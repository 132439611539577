<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="fixed">
        <adv-search
          @search="advSearch"
          :is_date_range="true"
          :is_service="true"
          :is_status="true"
          :is_job_report="false"
          :is_location="false"
          :is_admin_sp_location="true"
          :is_admin_sp="true"
          :is_payment_status="true"
          :reportData="this.excelreports"
          :titleworksheet="this.titleworksheet"
          :json_fields="this.json_fields"
          :header="this.header"
          :report_name="'ORI Report.xls'"
        />
        <!-- <div class="row" v-if="reports.length > 0 && !getLoadingData">
          <div class="col-md-12">
            <download-excel
              class="btn btn-outline-primary ml-1"
              :data="excelreports"
              :header="header"
              :worksheet="search.date_from + ' - ' + search.date_to"
              name="location-job-report.xls"
              :fields="json_fields"
            >
              <i class="test-white fa fa-download"></i>
            </download-excel>
          </div>
        </div> -->
      </div>
      <div
        class="row"
        style="margin-top:4%"
        v-if="reports.length > 0 && !getLoadingData"
      >
        <div class="col-md-12">
          <v-table :columns="columns">
            <tr v-for="(item, index) in reports" :key="index">
              <td >
                {{ ++index }}
              </td>
              <td v-if="search.location == 'all'">
                {{ item.sp_name }}
              </td>
              <td>
                {{ item.location_name }}
              </td>

              <td>{{ item.applicant }}</td>
              <td>{{ item.service }}</td>
              <td>{{ item.ori_number }}</td>

              <td
                class="text-center"
                v-if="item.schedule_date != null && item.schedule_date != ''"
              >
                {{ dateFormat(item.schedule_date) }}
              </td>
              <td class="text-center" v-else>
                -
              </td>
              <td>{{ item.payment_status }}</td>
              <td>{{ item.service_status }}</td>
              <td class="text-right">
                $ {{ item.actual_rate | toFixedTwoDigit }}
              </td>
              <td class="text-right">
                $ {{ item.admin_markup | toFixedTwoDigit }}
              </td>
              <td class="text-right">
                $ {{ item.admin_tax_amount | toFixedTwoDigit }}
              </td>
              <td class="text-right">$ {{ item.sp_rate | toFixedTwoDigit }}</td>
            </tr>
            <tr>
              <td :colspan="search.location == 'all' ? 9 : 8">
                <b>Grand Total</b>
              </td>
              <td class="text-right">
                <b>
                  $
                  {{ total_actual_rate | toFixedTwoDigit }}</b
                >
              </td>

              <td class="text-right">
                <b>
                  $
                  {{ total_admin_rate | toFixedTwoDigit }}</b
                >
              </td>

              <td class="text-right">
                <b>
                  $
                  {{ total_admin_tax_amount | toFixedTwoDigit }}</b
                >
              </td>
              <td class="text-right">
                <b>
                  $
                  {{ total_sp_rate | toFixedTwoDigit }}</b
                >
              </td>
            </tr>
          </v-table>
        </div>
      </div>
      <div class="row" v-if="reports.length < 1 && !getLoadingData && !load">
        <div class="col-md-12 text-center">
          <h4>no report(s) found</h4>
        </div>
      </div>
      <div class="row" v-if="load" style="opacity: 0.8;">
        <div class="col-md-12 text-center">
          <h4>Please select the criteria</h4>
        </div>
      </div>

      <div class="row move-below" v-if="getLoadingData">
        <div class="col-md-12 text-center">
          <v-data-loader></v-data-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import {
  FETCH_CLIENTS,
  FETCH_SP_ORI_WISE_REPORT
} from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import vTable from "@/includes/table/Datatable";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
import AdvSearch from "@/includes/search/AdvSearch";
// import * as PDF417 from "pdf417-generator";
export default {
  components: {
    vTable,
    vDataLoader,
    AdvSearch
  },
  computed: {
    ...mapGetters(["currentUser", "getClients", "getSearchDate"]),

    total_sp_rate: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += parseFloat(element.sp_rate);
        });
      }
      return total;
    },
    total_actual_rate: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += parseFloat(element.actual_rate);
        });
      }
      return total;
    },
    total_admin_rate: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += parseFloat(element.admin_markup);
        });
      }
      return total;
    },
    total_admin_tax_amount: function() {
      let total = 0;
      if (this.reports.length != 0) {
        this.reports.forEach(element => {
          total += parseFloat(element.admin_tax_amount);
        });
      }
      return total;
    }
  },
  filters: {
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  data() {
    return {
      search: {
        service: "all",
        date_from: "",
        date_to: "",
        location: "all",
        service_status: "all",
        payment_status: "all",
        search_text: ""
      },
      titleworksheet: "",

      getLoadingData: false,
      columns: [
        {
          name: "location",
          width: "",
          label: "Location",
          class: ""
        },
        {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },

        {
          name: "Applicant Name",
          width: "",
          label: "Applicant Name",
          class: ""
        },
        {
          name: "Service Name",
          width: "",
          label: "Service ",
          class: ""
        },
        {
          name: "ORI",
          width: "",
          label: "ORI ",
          class: ""
        },

        {
          name: "Scheduled Date",
          width: "",
          label: "Scheduled Date",
          class: ""
        },
        {
          name: "Payment status",
          width: "",
          label: "Payment status",
          class: ""
        },
        {
          name: "Service Status",
          width: "",
          label: "Service Status",
          class: ""
        },
        {
          name: "Cost",
          width: "8%",
          label: "Cost",
          class: "text-right"
        },
        {
          name: "Markup",
          width: "8%",
          label: "Markup",
          class: "text-right"
        },

        {
          name: "Tax",
          width: "8%",
          label: "Tax",
          class: "text-right"
        },
        {
          name: "Selling Price",
          width: "8%",
          label: "Selling Price",
          class: "text-right"
        }
      ],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Report",
          route: ""
        },
        {
          id: 2,
          title: "Location Job Report",
          route: ""
        }
      ],
      reports: [],
      header: [],
      load: true,
      excelreports: [],
      json_fields: {
        "Service Provider": "sp_name",
        "Location	": "location_name",
        "Applicant Name": "applicant",
        Service: "service",
        ORI: "ori_number",
        "Schedule Date": "schedule_date",
        "Payment Status": "payment_status",
        "Service Status": "service_status",
        Cost: "actual_rate",
        Markup: "admin_markup",
        Tax: "admin_tax_amount",
        "Selling Price": "sp_rate"
      }
    };
  },

  mounted() {
    this.$store.dispatch(FETCH_CLIENTS);
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },

  methods: {
    advSearch(value) {
      this.getLoadingData = true;
      this.load = false;
      let { header, search, footer } = value;
      this.header = header;
      this.search = { ...search };
      this.titleworksheet = search.date_from + " - " + search.date_to;
      this.tablereset();

      this.$store
        .dispatch(FETCH_SP_ORI_WISE_REPORT, search)
        .then(res => {
          this.getLoadingData = false;
          this.reports = res.data;
          this.excelreports = [...res.data];
          let data = [...res.data];
          let sp_rate = 0;
          if (data.length != 0) {
            data.forEach(element => {
              sp_rate += parseFloat(element.sp_rate);
            });
          }
          let actual_rate = 0;
          if (data.length != 0) {
            data.forEach(element => {
              actual_rate += parseFloat(element.actual_rate);
            });
          }
          let admin_rate = 0;
          if (data.length != 0) {
            data.forEach(element => {
              admin_rate += parseFloat(element.admin_markup);
            });
          }
          let admin_tax_amount = 0;
          if (data.length != 0) {
            data.forEach(element => {
              admin_tax_amount += parseFloat(element.admin_tax_amount);
            });
          }
          let gtotal = {
            schedule_date: "",
            sp_name: "",
            applicant: "",
            location_name: "",
            service_name: "",
            payment_status: "",
            service_status: "Grand Total",
            actual_rate: actual_rate,
            admin_markup: admin_rate,
            admin_tax_amount: admin_tax_amount,
            sp_rate: sp_rate
          };
          this.excelreports.push(gtotal);
          let foo = {
            sp_name: "",
            schedule_date: "",
            applicant: "",
            location_name: "",
            service_name: "",
            payment_status: "",
            service_status: "",
            actual_rate: footer[3],
            admin_markup: footer[2],
            admin_tax_amount: footer[1],
            sp_rate: footer[0]
          };
          this.excelreports.push(foo);
        })
        .catch(err => {
          this.getLoadingData = false;
          this.msg = err.error;
        });
    },
    tablereset() {
      if (this.search.location != "all") {
        this.columns = [
        {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },
          {
            name: "Location",
            width: "",
            label: "Location",
            class: ""
          },
          {
            name: "Applicant Name",
            width: "",
            label: "Applicant Name",
            class: ""
          },

          {
            name: "Service Name",
            width: "",
            label: "Service ",
            class: ""
          },
          {
            name: "ORI",
            width: "",
            label: "ORI ",
            class: ""
          },
          {
            name: "Scheduled Date",
            width: "",
            label: "Scheduled Date",
            class: ""
          },
          {
            name: "Payment status",
            width: "",
            label: "Payment status",
            class: ""
          },
          {
            name: "Service Status",
            width: "",
            label: "Service Status",
            class: ""
          },
          {
            name: "Cost",
            width: "8%",
            label: "Cost",
            class: " text-right"
          },
          {
            name: "Markup",
            width: "8%",
            label: "Markup",
            class: " text-right"
          },

          {
            name: "Tax",
            width: "8%",
            label: "Tax",
            class: " text-right"
          },
          {
            name: "Selling Price",
            width: "8%",
            label: "Selling Price",
            class: " text-right"
          }
        ];
        this.json_fields = {
          "Location	": "location_name",
          "Applicant Name": "applicant",
          Service: "service",
          ORI: "ori_number",
          "Schedule Date": "schedule_date",
          "Payment Status": "payment_status",
          "Service Status": "service_status",
          Cost: "actual_rate",
          Markup: "admin_markup",
          Tax: "admin_tax_amount",
          "Selling Price": "sp_rate"
        };
      } else {
        this.json_fields = {
          "Service Provider": "sp_name",
          "Location	": "location_name",
          "Applicant Name": "applicant",
          Service: "service",
          ORI: "ori_number",
          "Schedule Date": "schedule_date",
          "Payment Status": "payment_status",
          "Service Status": "service_status",
          Cost: "actual_rate",
          Markup: "admin_markup",
          Tax: "admin_tax_amount",
          "Selling Price": "sp_rate"
        };
        this.columns = [
        {
          name: "S.No",
          width: "",
          label: "S.No",
          class: ""
        },
          {
            name: "Service Provider",
            width: "",
            label: "Service Provider",
            class: ""
          },
          {
            name: "location",
            width: "",
            label: "Location",
            class: ""
          },
          {
            name: "Applicant Name",
            width: "",
            label: "Applicant Name",
            class: ""
          },
          {
            name: "Service Name",
            width: "",
            label: "Service ",
            class: ""
          },
          {
            name: "ORI",
            width: "",
            label: "ORI ",
            class: ""
          },
          {
            name: "Scheduled Date",
            width: "",
            label: "Scheduled Date",
            class: ""
          },
          {
            name: "Payment status",
            width: "",
            label: "Payment status",
            class: ""
          },
          {
            name: "Service Status",
            width: "",
            label: "Service Status",
            class: ""
          },
          {
            name: "Cost",
            width: "8%",
            label: "Cost",
            class: " text-right"
          },
          {
            name: "Markup",
            width: "8%",
            label: "Markup",
            class: " text-right"
          },

          {
            name: "Tax",
            width: "8%",
            label: "Tax",
            class: " text-right"
          },
          {
            name: "Selling Price",
            width: "8%",
            label: "Selling Price",
            class: " text-right"
          }
        ];
      }
    },
    refreshData() {
      this.pagination = {
        total_records: 0,
        total_page: 0,
        current_page: 1,
        per_page_data: 15,
        received_per_page_data: 15
      };
    },

    statusColor(status) {
      if (status == "P") {
        return "warning";
      }
      if (status == "C") {
        return "success";
      }
      if (status == "U") {
        return "info";
      }
      if (status == "Cancel") {
        return "danger";
      }
    },
    selectedPage(n) {
      this.pagination.current_page = n;
      //   this.appData();
    },
    nextPage() {
      this.pagination.current_page = this.pagination.current_page + 1;
      //   this.appData();
    },
    lastPage() {
      this.pagination.current_page = this.pagination.total_page;
      //   this.appData();
    },
    firstPage() {
      this.pagination.current_page = 1;
      //   this.appData();
    },
    previousPage() {
      this.pagination.current_page = this.pagination.current_page - 1;
      //   this.appData();
    }
  }
};
</script>
